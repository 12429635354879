<template>
  <div>
    <div class="page-title">
      <h3>Редактирование проекта</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/projects')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <Loader v-if="loading"/>

    <form class="form" @submit.prevent="submitHandler">
      <div class="row">
        <div class="input-field">
          <input id="id" type="text" v-model="id" disabled>
          <label for="id">ID</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="name" type="text" v-model="name" :class="{invalid: $v.name.$error}">
          <label for="name">Название</label>
          <small class="helper-text invalid" v-if="$v.name.$error">Не указано название</small>
        </div>
      </div>

      <button class="btn waves-effect waves-light blue darken-4" type="submit">
        Сохранить
        <i class="material-icons right">send</i>
      </button>
    </form>
  </div>
</template>

<script>
import {required, numeric} from 'vuelidate/lib/validators'
import dateFilter from '../../filters/date.filter'
import convertDateString from '../../utils/date'

export default {
  metaInfo() {
    return {
      title: 'Редактирование проекта'
    }
  },
  data: () => ({
    loading: true,
    id: '',
    name: '',
  }),
  validations: {
    name: {required},
  },
  async mounted() {
    const project = await this.$store.dispatch('fetchProjectById', this.$route.params.id)

    this.id = project.id
    this.name = project.name || ''

    this.loading = false

    setTimeout(() => {
      M.updateTextFields()
    })
  },
  methods: {
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const project = {
        id: this.id,
        name: this.name,
      }

      await this.$store.dispatch('updateProject', project)
      await this.$router.push('/projects')
    },
  },
}
</script>
